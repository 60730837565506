
   .border_bruh {
    border: 1px solid #26d460;
    background-color: #FFFFFF;
}

.border_bruhie {
    border: 1px solid #000000;
    background-color: #FFFFFF;
}

.nav-link {
    color: #000000;
    position: relative;
    transition: all 0.2s; /* Add a duration for the transition */
}

.nav-link:hover,
.nav-link.active {
    color: rgb(0, 0, 0);
    font-weight: bold;
}

.nav-link.active {
    font-weight: bolder;
}

.nav-link::after {
    content: '';
    height: 4px;
    width: 100%;
    background: #1A9A6F;
    position: absolute;
    left: 0;
    bottom: -2px;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
}

.nav-link:hover::after,
.nav-link.active::after {
    opacity: 1;
}


  .break-words {
    word-break: break-word;
    overflow-wrap: break-word;
  }


  @media (max-width: 640px) { /* Mobile screens */
    .break-words {
      white-space: pre-wrap; 
      /* Allows line breaks */
    }
  }
  .break-words {
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }

  @media (max-width: 640px) { /* Mobile screens */
    .text-2xl {
      font-size: 1.5rem; /* Adjust font size for mobile */
    }
  }


  .container {
    background-color: #FFFFFF;
    padding: 60px;
  }
  
  .frame2 {
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  .header {
    display: flex;
    flex-direction: column;
    margin-bottom: 54px;
  }
  
  .header-content {
    margin-bottom: 54px;
  }
  
  .title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #1A9A6F;
  }
  
  .subtitle {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 26px;
    color: rgba(0, 0, 0, 0.8);
    margin: 0 0 19px;
  }
  
  .description {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    margin: 0 0 19.3px;
  }
  
  .experience {
    display: flex;
    justify-content: space-between;
    margin-top: 19.3px;
  }
  
  .experience-item {
    display: flex;
    align-items: center;
  }
  
  .experience-item img {
    margin-right: 19.8px;
  }
  
  .experience-text {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 22px;
    color: rgba(0, 0, 0, 0.8);
  }
  
  .label {
    display: block;
    font-weight: normal;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
  }
  
  .timeline {
    display: flex;
    flex-direction: column;
    margin-left: 61px;
  }
  
  .timeline-item {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
  }
  
  .timeline-year {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #000;
    margin-right: 18px;
  }
  
  .timeline-details {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #000;
  }
  
  .services {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .service {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: 402px;
  }
  
  .service img {
    margin-bottom: 20px;
  }
  
  .service h4 {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    color: #000;
    margin-bottom: 14px;
  }
  
  .service p {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #000;
    line-height: 1.5;
  }
  
  @media (max-width: 1200px) {
    .frame2 {
      padding: 30px;
    }
  }
  
  @media (max-width: 768px) {
    .frame2 {
      padding: 20px;
    }
  
    .services {
      flex-direction: column;
    }
  
    .service {
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 18px;
    }
  
    .subtitle {
      font-size: 22px;
    }
  
    .description {
      font-size: 12px;
    }
  
    .experience-text {
      font-size: 20px;
    }
  
    .timeline-year {
      font-size: 16px;
    }
  
    .timeline-details {
      font-size: 12px;
    }
  
    .service h4 {
      font-size: 20px;
    }
  
    .service p {
      font-size: 12px;
    }
  }





  .timeline {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
  
  .timeline-item {
    display: flex;
    align-items: center;
  }
  
  .timeline-year {
    width: 100px;
    font-weight: bold;
  }
  
  .timeline-bar {
    height: 30px;
    background-color: #4caf50;
    margin-right: 10px;
    border-radius: 5px;
  }
  
  .timeline-details {
    font-size: 16px;
  }
  




  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .header-content {
      flex-direction: column;
    }
  
    .experience {
      flex-direction: column;
    }
  
    .experience-item {
      margin-bottom: 10px;
    }
  }
  




/* diamond about us */

  .diamond {
    transform: rotate(45deg);
  }
  
  .diamond-text {
    transform: rotate(-45deg);
  }




  /* frame 6 */
/* 
  body {
    margin: 0;
    font-family:  'Poppins',;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
   */
  .App {
    text-align: center;
  }
  
  .App-header {
    background-color: #1888ad;
    padding: 20px;
    color: white;
  }
  
  .steps-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    position: relative;
  }
  
  .step {
    background-color: #ffffff;
    border-radius: 8px;
    margin: 10px;
    padding: 20px;
    max-width: 300px;
    flex: 1 1 200px;
    text-align: left;
    position: relative;
    color: #0c0c0c;
  }
  
  .step h2 {
    color: #0f0f0f;
    font-size: larger;
  }
  
  .step h3 {
    margin-top: 0;
    font-size: larger;
  }
  
  .step::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
   
    position: absolute;
    bottom: -10px;
    left: 0;
  }
  
 



/* frame 2 */

/* Spamie.css */

/* General Styles */
.header-content h2 {
  font-size: 1.5rem;
}

.header-content h3 {
  font-size: 1.25rem;
}

.header-content p {
  font-size: 1rem;
}

.service img {
  width: 4.5rem;
  height: 4.5rem;
}

.service h4 {
  font-size: 1rem;
}

.service p {
  font-size: 0.875rem;
}

/* Media Queries */
@media (min-width: 640px) {
  .header-content h2 {
    font-size: 2rem;
  }

  .header-content h3 {
    font-size: 1.5rem;
  }

  .header-content p {
    font-size: 1.125rem;
  }

  .service h4 {
    font-size: 1.125rem;
  }

  .service p {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .header-content h2 {
    font-size: 2.5rem;
  }

  .header-content h3 {
    font-size: 1.75rem;
  }

  .header-content p {
    font-size: 1.25rem;
  }

  .service h4 {
    font-size: 1.25rem;
  }

  .service p {
    font-size: 1.125rem;
  }
}

@media (min-width: 1024px) {
  .header-content h2 {
    font-size: 3rem;
  }

  .header-content h3 {
    font-size: 2rem;
  }

  .header-content p {
    font-size: 1.5rem;
  }

  .service h4 {
    font-size: 1.5rem;
  }

  .service p {
    font-size: 1.25rem;
  }
}

/* Ensure the SVG and icons scale appropriately */
.svg-container {
  width: 100%;
  height: auto;
}

.service img {
  width: auto;
  height: auto;
}



/* phone number */


/* styles.css or index.css */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Hide arrows in WebKit browsers */
  margin: 0; /* Remove default margin */
}

input[type="number"] {
  -moz-appearance: textfield; /* Hide arrows in Firefox */
}






/* services */


/* Add this CSS to your main CSS file */
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 350px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 9px;
}

.flip-card-front {
  background: linear-gradient(29.8deg,#262638 9.73%,#393F5D 67.76%);
  color: white;
}

.flip-card-back {
  background-color: #1A9A6F;
  color: white;
  transform: rotateY(180deg);
}




/* frame 6 */

/* General Styles for Steps Container */
.steps-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  padding: 1rem;
}

.step {
  border: 1px solid #4CAF50; /* Adjust border color as needed */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.step h3 {
  font-size: 1.25rem;
}

.step p {
  font-size: 1rem;
  color: #666;
}

.arrow {
  /* Arrow styling if needed */
}

/* Responsive Styles */
@media (min-width: 768px) { /* For medium devices (tablets) */
  .steps-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1280px) { /* For extra-large devices (desktops) */
  .steps-container {
    grid-template-columns: repeat(4, 1fr);
  }
}






/* Demo button */
.custom-button {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 40px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.25);
}

.custom-button span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background: linear-gradient(90deg, #1888ad 0%, #182848 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 18px;
  
}

.custom-button span:nth-child(2) {
  color: #6c4ba5;
  background: #fff;
  overflow: hidden;
  z-index: 2;
  transition: 0.5s;
  clip-path: polygon(60% 0, 100% 0%, 100% 100%, 60% 100%, 45% 53%);
}

.custom-button span:nth-child(2):hover {
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%, 0 50%);
}

.custom-button span:nth-child(1):hover ~ span:nth-child(2) {
  clip-path: polygon(100% 0, 100% 0%, 100% 100%, 100% 100%, 100% 50%);
}







/* backround */
/* 
@keyframes bgZoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.animate-bgZoom {
  animation: bgZoom 15s ease-in-out infinite alternate;
}


@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeInUp {
  animation: fadeInUp 1.5s ease-in-out forwards;
}

.animate-delay {
  animation-delay: 0.5s;
} */



@keyframes textExplosion {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(30px);
  }
  50% {
    opacity: 1;
    transform: scale(1.1) translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.text-explosion {
  opacity: 0;
  animation: textExplosion 1s ease-in-out forwards;
}

.text-explosion:nth-child(1) {
  animation-delay: 0.1s;
}

.text-explosion:nth-child(2) {
  animation-delay: 0.2s;
}

.text-explosion:nth-child(3) {
  animation-delay: 0.3s;
}

@keyframes glitch {
  0% {
    clip-path: inset(0 0 0 0);
    transform: translate(0);
  }
  20% {
    clip-path: inset(10% 0 15% 0);
    transform: translate(-5px, -5px);
  }
  40% {
    clip-path: inset(80% 0 3% 0);
    transform: translate(5px, 5px);
  }
  60% {
    clip-path: inset(10% 0 15% 0);
    transform: translate(-5px, 5px);
  }
  80% {
    clip-path: inset(80% 0 3% 0);
    transform: translate(5px, -5px);
  }
  100% {
    clip-path: inset(0 0 0 0);
    transform: translate(0);
  }
}
/* 
.glitch {
  position: relative;
  color: white;
  font-weight: bold;
}

.glitch:before,
.glitch:after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  clip-path: inset(0 0 0 0);
}

.glitch:before {
  color: lime;
  z-index: -2;
  animation: glitch 2s infinite;
}

.glitch:after {
  color: red;
  z-index: -1;
  animation: glitch 1s infinite;
} */





/* demo button */
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.blinking-button {
  animation: blink 1s infinite;
}



